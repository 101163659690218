<template>
  <div>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="问卷名称" prop="title" show-overflow-tooltip />
      <el-table-column
        label="问卷封面"
        prop="channelQrcodeUrl"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.coverUrl"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="420">
        <template #default="{ row }">
          <el-button
            v-loading.fullscreen.lock="fullscreenLoading"
            type="info"
            plain
            @click="handleDownLoad(row)"
          >
            导出
          </el-button>
          <el-button type="info" plain @click="handlePreview(row)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <edit ref="editRef" @fetch-data="fetchData" />
    <generateWxVue ref="generateWxRef"></generateWxVue>
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
    computed,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import generateWxVue from '@/components/generateWx/generateWx.vue'
  import handleClipboard from '@/utils/clipboard'
  import { useStore } from 'vuex'
  import {
    getquestioniareList,
    editQuestioniareList,
    exportQuestion,
  } from '@/api/form'
  export default defineComponent({
    name: 'formList.vue',
    components: {
      Edit: defineAsyncComponent(() => import('./components/formEdit.vue')),
      generateWxVue,
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const store = useStore()
      const userInfo = computed(() => store.getters['user/user'])
      const routes = computed(() => store.getters['routes/routes'])
      const state = reactive({
        queryForm: {
          title: '',
          pageNum: 1,
          pageSize: 20,
        },
        total: 0,
        shopList: [],
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        generateWxRef: null,
        fullscreenLoading: false,
      })

      const handlePreview = (row) => {
        console.log(row)
        state['generateWxRef'].handleOpen(row, null, true)
      }
      const handleEdit = (row) => {
        console.log(row)
        if (row.code) {
          state.editRef.showEdit(row)
        } else {
          state['editRef'].showEdit()
        }
      }

      const handleDelete = (row) => {
        if (row.code) {
          row.delFlag = true
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await editQuestioniareList(row)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const handleConfig = (row) => {
        proxy.$router.push({
          path: '/form/config',
          query: { code: row.code },
        })
      }

      const handleDownLoad = async (row) => {
        state.fullscreenLoading = true
        const { data } = await exportQuestion({
          feedbackCode: row.code,
          channel: userInfo.value.remarks,
        })
        console.log(data)
        window.open(data)
        state.fullscreenLoading = false
      }

      const queryData = () => {
        fetchData()
      }

      const copy = (row, event) => {
        handleClipboard(row.channelUrl, event)
      }
      const downLoad = (row) => {
        var image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        var _this = this
        image.onload = function () {
          var canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          var context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          var url = canvas.toDataURL('image/jpg') //得到图片的base64编码数据
          var a = document.createElement('a') // 生成一个a元素
          var event = new MouseEvent('click') // 创建一个单击事件
          a.download = '渠道二维码' // 设置图片名称
          a.href = url // 将生成的URL设置为a.href属性
          a.dispatchEvent(event) // 触发a的单击事件
        }
        image.src = row.channelQrcodeUrl
      }
      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getquestioniareList(state.queryForm)
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        queryData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        queryData()
      }

      onActivated(() => {
        queryData()
      })

      // 观众根据渠道导入
      const visitorImport = (row) => {
        state.upLoadRef.showEdit(row)
      }

      // 查询导出结果
      const exportResult = (row) => {
        state.resultRef.showEdit(row)
      }

      const visitorExport = (row) => {
        console.log(row)
        const params = {
          eventId: state.queryForm.eventId,
          channelNo: row.channelNo,
        }
        _exportUserInfo(params).then((res) => {
          console.log(res)
          if (!res.data.success) {
            proxy.$baseMessage(
              `当前数据为空，无法导出`,
              'error',
              'vab-hey-message-error'
            )
          } else {
            window.open(res.data.fileUrl)
          }
        })
      }

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        copy,
        downLoad,
        visitorImport,
        visitorExport,
        exportResult,
        handleConfig,
        Delete,
        Search,
        Plus,
        handlePreview,
        handleDownLoad,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
